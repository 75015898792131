
* {
  min-width: 0;
}

.ResourceEditItem {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
}

.ResourceEditItemSidebar {
  box-sizing: border-box;
  min-width: 0;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-right: 1px solid #e5e5e5;
}

.ResourceEditItemContent {
  box-sizing: border-box;
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.ResourceEditItemFileUrl {
  box-sizing: border-box;
  min-width: 0;
  width: 100%;
}

.ResourceEditItemType {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.ResourceEditItemButtons {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
